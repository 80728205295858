<template lang="html">
  <svg
    width="28"
    height="23"
    viewBox="0 0 28 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0084 19.4435C14.0084 19.9368 13.7234 20.3849 13.2774 20.594C13.105 20.6756 12.9202 20.715 12.7366 20.715C12.4459 20.715 12.1579 20.6151 11.9252 20.4222L4.97384 14.662H1.27152C0.569402 14.6626 0 14.0929 0 13.3907V8.32481C0 7.62239 0.569402 7.05299 1.27152 7.05299H4.97414L11.9255 1.29276C12.3053 0.977966 12.832 0.910854 13.2777 1.12152C13.7234 1.33068 14.0087 1.7791 14.0087 2.27206L14.0084 19.4435Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "CallAgainOne",
};
</script>
